import React, {ReactNode} from "react";

type Unpacked<T> = T extends React.Context<infer U> ? U : T;

interface ContextMap {
    readonly [i: string]: React.Context<any>;
}

interface ProviderProps<T extends ContextMap> {
    readonly contexts: T;
    readonly values: {[K in keyof T]: Unpacked<T[K]>};
}

interface ConsumerProps<T extends ContextMap> {
    readonly contexts: T;
    readonly children: (values: {[K in keyof T]: Unpacked<T[K]>}) => ReactNode;
}

export const MultiContext = {
    Provider: class<T extends ContextMap> extends React.Component<ProviderProps<T>, any> {
        public render(): ReactNode {
            const {contexts, values, children} = this.props;

            return Object.keys(contexts).reduce((child: ReactNode, name: string) => {
                const Context = contexts[name];
                const value = values[name];
                return <Context.Provider value={value}>{child}</Context.Provider>;
            }, children);
        }
    },

    Consumer: class<T extends ContextMap> extends React.Component<ConsumerProps<T>, any> {
        public render(): ReactNode {
            const {contexts, children} = this.props;

            const r = Object.keys(contexts).reduce((child, name: string) => {
                const Context = contexts[name];

                return (value: any) => (
                    <Context.Consumer>
                        {(ctxValue) =>
                            // eslint-disable-next-line
                            child({...value, [name]: ctxValue} as {[K in keyof T]: Unpacked<T[K]>})
                        }
                    </Context.Consumer>
                );
            }, children);

            return r({} as {[K in keyof T]: Unpacked<T[K]>});
        }
    },
};

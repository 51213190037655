import React, {ReactNode} from "react";
import {HostService} from "../../services/host/HostService";
import {Manifest, Plugin} from "@interactio/plugin-sdk";
import {SubComponent} from "../../utilities/SubComponent";
import {SelectedPluginControls} from "./selected-plugin-controls/SelectedPluginControls";
import {
    Grid,
    GridAlignItems,
    GridDirection,
    GridItem,
    Header,
    Option,
    Select,
    Size
} from "@interactio/component-library";

interface Props {
    hostService: HostService;
}

interface State {
    manifest?: Manifest;
    selectedPluginId: number;
    plugins: readonly Plugin[];
}

export class PluginControls extends SubComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            manifest: props.hostService.manifest,
            plugins: [],
            selectedPluginId: -1
        };
    }

    protected subscribe(): void {
        this.props.hostService
            .addObserver("onPluginMounted", this, this.onPluginMounted)
            .addObserver("onPluginWillUnmount", this, this.onPluginWillUnmount)
            .addObserver("onManifestUpdated", this, this.onManifestUpdated);
    }

    private onPluginMounted(pluginId: number): void {
        const currentSelectedPluginId = this.state.selectedPluginId;
        const newSelectedPluginId = currentSelectedPluginId === -1 ? pluginId : currentSelectedPluginId;

        this.setState({plugins: this.props.hostService.plugins, selectedPluginId: newSelectedPluginId});
    }

    private onPluginWillUnmount(pluginId: number): void {
        const plugins = this.state.plugins.filter((plugin) => plugin.id !== pluginId);

        let {selectedPluginId} = this.state;

        if (pluginId === selectedPluginId)
            selectedPluginId = plugins[0]?.id || -1;

        this.setState({plugins, selectedPluginId});
    }

    private onManifestUpdated(manifest?: Manifest): void {
        this.setState({manifest});
    }

    protected unsubscribe(): void {
        this.props.hostService.removeObserver(this);
    }

    private onSelectedPluginChange(value: string | number): void {
        // Component library limitation
        const pluginId = String(value).split("plugin-")[1];

        this.setState({selectedPluginId: Number(pluginId)});
    }

    public render(): ReactNode {
        const {hostService} = this.props;
        const {selectedPluginId, plugins} = this.state;
        const selectedPlugin = plugins.find((plugin: Plugin) => plugin.id === selectedPluginId);

        return (
            <div className={"sidebarWrapper"}>
                <Grid aroundSpacing={2} spacing={0} direction={GridDirection.Column}>
                    <GridItem xs>
                        <Grid direction={GridDirection.Column}
                              alignItems={GridAlignItems.Stretch}
                              spacing={4}
                              aroundSpacing={0}>

                            <GridItem>
                                <Header title={"Plugin controls"}/>
                            </GridItem>

                            {/* Plugin selection */}
                            <GridItem>
                                {
                                    plugins.length > 0 &&
                                    <Select
                                        name={"plugin-selection"}
                                        value={"plugin-" + selectedPluginId}
                                        label={"Plugin"}
                                        onChange={this.onSelectedPluginChange.bind(this)}>

                                        {plugins.map((plugin) =>
                                            (<Option value={"plugin-" + plugin.id} key={plugin.id}>
                                                {`${plugin.manifest.title} (${plugin.id})`}
                                            </Option>))}
                                    </Select>
                                }

                                {plugins.length === 0 && <Header title={"No plugins mounted"} size={Size.Small}/>}
                            </GridItem>

                            <GridItem>
                                {
                                    selectedPlugin &&
                                    <SelectedPluginControls hostService={hostService} plugin={selectedPlugin}/>
                                }
                            </GridItem>
                        </Grid>
                    </GridItem>
                </Grid>
            </div>
        );
    }
}

import React, {ReactNode} from "react";
import {SubComponent} from "../../../utilities/SubComponent";
import {Notification, NotificationAction, NotificationType} from "@interactio/plugin-sdk";
import {Button, ButtonColor, ButtonElement, ButtonVariant, Message, MessageType} from "@interactio/component-library";

interface Props {
    notification: Notification;
    onClose: () => void;
}

interface State {
    loading: boolean;
}

export class NotificationItem extends SubComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            loading: false
        };
    }

    private triggerAction(action: NotificationAction): void {
        this.setState({loading: true}, () => {
            action
                .trigger()
                .finally(() => {
                    this.props.onClose();
                });
        });
    }

    private messageType(): MessageType {
        const {notification} = this.props;
        const {loading} = this.state;

        if (loading)
            return MessageType.Loading;

        switch (notification.type) {
            case NotificationType.Warning:
                return MessageType.Warning;
            case NotificationType.Error:
                return MessageType.Error;
            case NotificationType.Info:
                return MessageType.Info;
            case NotificationType.Success:
                return MessageType.Success;
            default:
                return MessageType.Info;
        }
    }


    public render(): ReactNode {
        const {notification, onClose} = this.props;

        const actionButtons: ButtonElement[] = notification.actions?.map(
            (action: NotificationAction) => {
                return (
                    <Button
                        key={`action-${action.name || "default"}`}
                        color={ButtonColor.Gray}
                        fullWidth
                        variant={ButtonVariant.Outlined}
                        onClick={() => this.triggerAction(action)}>
                        {action.title}
                    </Button>
                );
            }) || [];

        actionButtons.push(
            <Button
                key={"action-close-notification"}
                color={ButtonColor.Gray}
                fullWidth
                variant={ButtonVariant.Outlined}
                onClick={onClose}>
                Close
            </Button>
        );

        return (
            <>
                <Message
                    type={this.messageType()}
                    description={notification.message}
                    title={notification.title || " "}>
                    {actionButtons}
                </Message>
            </>
        );
    }
}

import React, {ReactNode} from "react";
import {HostService} from "../../services/host/HostService";
import {Manifest, Placement} from "@interactio/plugin-sdk";
import {PluginHook} from "./plugin-hook/PluginHook";
import {SubComponent} from "../../utilities/SubComponent";
import {PluginModalHook} from "./plugin-modal-hook/PluginModalHook";

interface Props {
    hostService: HostService;
}

interface State {
    manifest?: Manifest;
}

export class SandboxLayout extends SubComponent<Props, State> {
    private widgets: string[] = [];
    private frames: string[] = [];
    private modals: string[] = [];

    public constructor(props: Props) {
        super(props);

        const {manifest} = props.hostService;

        this.state = {
            manifest
        };

        this.updateHooks(manifest);
    }

    protected subscribe(props: Props): void {
        this.props.hostService
            .addObserver("onManifestUpdated", this, this.onManifestUpdated);
    }

    private onManifestUpdated(manifest?: Manifest): void {
        this.updateHooks(manifest);

        this.setState({manifest});
    }

    private updateHooks(manifest?: Manifest): void {
        let hooks: string[] = [];
        this.widgets = [];
        this.frames = [];
        this.modals = [];

        if (manifest && manifest.hooks) {
            hooks = Object.keys(manifest?.hooks);

            hooks.forEach((hook) => {
                const placement: Placement | undefined = manifest.hooks?.[hook] as Placement;

                if (!placement)
                    return;

                switch (placement) {
                    case Placement.Widget:
                        this.widgets.push(hook);
                        break;
                    case Placement.Frame:
                    case Placement.Fullscreen:
                        this.frames.push(hook);
                        break;
                    case Placement.Modal:
                        this.modals.push(hook);
                    // no default
                }
            });
        }
    }

    protected unsubscribe(props: Props): void {
        this.props.hostService.removeObserver(this);
    }

    public render(): ReactNode {
        const {hostService} = this.props;

        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                height: "100%"
            }}>
                {/* Widgets */}
                {this.widgets.length > 0 &&
                    <div style={{
                        height: this.frames.length > 0 ? "15vh" : "100%",
                        display: "flex",
                        overflow: "auto"
                    }}>
                        {this.widgets.map((hook) =>
                            <PluginHook hostService={hostService} name={hook} key={hook} type={"Widget"}/>)}
                    </div>
                }

                {/* Frames */}
                {this.frames.length > 0 &&
                    <div style={{
                        flex: 1,
                        display: "flex",
                        overflow: "auto"
                    }}>
                        {this.frames.map((hook) =>
                            <PluginHook hostService={hostService} name={hook} key={hook} type={"Frame"}/>)}
                    </div>
                }

                {/* Modal plugins */}
                {this.modals.length > 0 &&
                    <div style={{
                        width: 0,
                        height: 0
                    }}>
                        {this.modals.map((hook) =>
                            <PluginModalHook hostService={hostService} name={hook} key={hook}/>)}
                    </div>
                }
            </div>
        );
    }
}

import React, {ReactNode} from "react";
import {SubComponent} from "../../../utilities/SubComponent";
import {Plugin} from "@interactio/plugin-sdk";
import {HostService} from "../../../services/host/HostService";
import {PluginActions} from "../plugin-actions/PluginActions";
import {PluginEvents} from "../plugin-events/PluginEvents";
import {Button, Grid, GridItem, IconName} from "@interactio/component-library";
import {Debug} from "@interactio/ts-sdk";

interface Props {
    plugin: Plugin;
    hostService: HostService;
}

export class SelectedPluginControls extends SubComponent<Props> {
    private onUnmountButtonClick(): void {
        this.props.hostService.unmountPlugin(this.props.plugin.id);
    }

    private onRemountButtonClick(): void {
        const {plugin} = this.props;

        this.props.hostService.unmountPlugin(this.props.plugin.id);

        this.props.hostService.hostUtility?.mount(plugin.manifest.url, plugin.options)
            .catch((error) => {
                Debug.warn("Failed to remount plugin: ", error);
            });
    }

    public render(): ReactNode {
        const {plugin, hostService} = this.props;

        return (
            <Grid spacing={2}>

                <GridItem>
                    <Grid spacing={2}>
                        <GridItem xs={6}>
                            <Button
                                fullWidth={true}
                                onClick={this.onUnmountButtonClick.bind(this)}
                                leftIconName={IconName.Trash}>
                                Unmount
                            </Button>
                        </GridItem>

                        <GridItem xs={6}>
                            <Button
                                fullWidth={true}
                                onClick={this.onRemountButtonClick.bind(this)}
                                leftIconName={IconName.Refresh}>
                                Remount
                            </Button>
                        </GridItem>
                    </Grid>
                </GridItem>

                <GridItem>
                    <PluginActions plugin={plugin} hostService={hostService}/>
                </GridItem>

                <GridItem>
                    <PluginEvents plugin={plugin} hostService={hostService}/>
                </GridItem>
            </Grid>
        );
    }
}

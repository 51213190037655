import React, {ReactNode} from "react";
import {HostService} from "../../../services/host/HostService";
import {SubComponent} from "../../../utilities/SubComponent";
import {Plugin} from "@interactio/plugin-sdk";
import {Checkbox, Header, Size} from "@interactio/component-library";

interface Props {
    plugin: Plugin;
    hostService: HostService;
}

interface State {
    monitoredEvents: Set<string>;
}

export class PluginEvents extends SubComponent<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            monitoredEvents: this.getMonitoredEventSet()
        };
    }

    private getMonitoredEventSet(): Set<string> {
        const events = this.props.hostService.getMonitoredEvents(this.props.plugin);

        const eventNames = events.keys();

        return new Set<string>([...eventNames]);
    }

    protected subscribe(props: Props): void {
        this.setState({monitoredEvents: this.getMonitoredEventSet()});
    }

    protected shouldResubscribe(newProps: Props, oldProps: Props): boolean {
        return newProps.plugin !== oldProps.plugin;
    }

    private onMonitoredEventToggle(checked: boolean, eventName: string): void {
        const {hostService, plugin} = this.props;
        const {monitoredEvents} = this.state;

        if (checked) {
            monitoredEvents.add(eventName);

            hostService.addMonitoredEvent(plugin, eventName);
        } else {
            monitoredEvents.delete(eventName);

            hostService.removeMonitoredEvent(plugin, eventName);
        }

        this.setState({monitoredEvents});
    }

    public render(): ReactNode {
        const {plugin} = this.props;
        const {monitoredEvents} = this.state;

        const manifest = plugin.manifest;
        const eventNames = Object.keys(plugin.manifest.events);

        return (
            <>
                <Header title={"Events"}/>

                {manifest.events &&
                    eventNames.map((event: string) =>
                        (<div key={`plugin-event-${event}`}>
                            <Checkbox
                                active={monitoredEvents.has(event)}
                                name={`${event}`}
                                label={event}
                                onChange={(checked) => {
                                    this.onMonitoredEventToggle(checked, event);
                                }}
                            />
                        </div>))
                }

                {eventNames.length === 0 && <Header title={"Plugin does not have any events"} size={Size.Small}/>}
            </>
        );
    }
}

import {HostService} from "../../../services/host/HostService";
import React, {Component, ReactNode, RefObject} from "react";
import styles from "./PluginHook.module.scss";
import {Header} from "@interactio/component-library";

interface Props {
    hostService: HostService;
    name: string;
    type: string;
}

export class PluginHook extends Component<Props> {
    private readonly ref: RefObject<HTMLDivElement>;

    public constructor(props: Props) {
        super(props);

        this.ref = React.createRef<HTMLDivElement>();
    }

    public componentDidMount(): void {
        this.props.hostService.hostUtility?.registerHook(this.props.name, this.placementProvider.bind(this));
    }

    private async placementProvider(): Promise<HTMLDivElement> {
        if (!this.ref.current)
            throw new Error(`Error providing placement for hook ${this.props.name}`);

        return this.ref.current;
    }


    public render(): ReactNode {
        return (
            <div ref={this.ref} className={styles.pluginHook}>
                <div className={styles.hookName}>
                    <Header title={`${this.props.name} hook (${this.props.type})`}/>
                </div>
            </div>
        );
    }
}
